import _readingTime from "./reading-time";
import * as _stream2 from "stream";

var _stream = "default" in _stream2 ? _stream2.default : _stream2;

import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

var exports = {};

/**
 * Module dependencies.
 */
const readingTime = _readingTime;
const Transform = _stream.Transform;
const util = _util;
/**
 * @typedef {import('reading-time').Options} Options
 * @typedef {import('reading-time').Options['wordBound']} WordBoundFunction
 * @typedef {import('reading-time').readingTimeStream} ReadingTimeStream
 * @typedef {import('stream').TransformCallback} TransformCallback
 */

/**
 * @param {Options} options
 * @returns {ReadingTimeStream}
 */

function ReadingTimeStream(options) {
  // allow use without new
  if (!(this instanceof ReadingTimeStream)) {
    return new ReadingTimeStream(options);
  }

  Transform.call(this, {
    objectMode: true
  });
  this.options = options || {};
  this.stats = {
    minutes: 0,
    time: 0,
    words: 0
  };
}

util.inherits(ReadingTimeStream, Transform);
/**
 * @param {Buffer} chunk
 * @param {BufferEncoding} encoding
 * @param {TransformCallback} callback
 */

ReadingTimeStream.prototype._transform = function (chunk, encoding, callback) {
  const stats = readingTime(chunk.toString(encoding), this.options);
  this.stats.minutes += stats.minutes;
  this.stats.time += stats.time;
  this.stats.words += stats.words;
  callback();
};
/**
 * @param {TransformCallback} callback
 */


ReadingTimeStream.prototype._flush = function (callback) {
  this.stats.text = Math.ceil(this.stats.minutes.toFixed(2)) + " min read";
  this.push(this.stats);
  callback();
};
/**
 * Export
 */


exports = ReadingTimeStream;
export default exports;