var exports = {};

/**
 * @typedef {import('reading-time').Options['wordBound']} WordBoundFunction
 */

/**
 * @param {number} number
 * @param {number[][]} arrayOfRanges
 */
function codeIsInRanges(number, arrayOfRanges) {
  return arrayOfRanges.some(([lowerBound, upperBound]) => lowerBound <= number && number <= upperBound);
}
/**
 * @type {WordBoundFunction}
 */


function isCJK(c) {
  if ("string" !== typeof c) {
    return false;
  }

  const charCode = c.charCodeAt(0); // Help wanted!
  // This should be good for most cases, but if you find it unsatisfactory
  // (e.g. some other language where each character should be standalone words),
  // contributions welcome!

  return codeIsInRanges(charCode, [// Hiragana (Katakana not included on purpose,
  // context: https://github.com/ngryman/reading-time/pull/35#issuecomment-853364526)
  // If you think Katakana should be included and have solid reasons, improvement is welcomed
  [12352, 12447], // CJK Unified ideographs
  [19968, 40959], // Hangul
  [44032, 55203], // CJK extensions
  [131072, 191456]]);
}
/**
 * @type {WordBoundFunction}
 */


function isAnsiWordBound(c) {
  return " \n\r\t".includes(c);
}
/**
 * @type {WordBoundFunction}
 */


function isPunctuation(c) {
  if ("string" !== typeof c) {
    return false;
  }

  const charCode = c.charCodeAt(0);
  return codeIsInRanges(charCode, [[33, 47], [58, 64], [91, 96], [123, 126], // CJK Symbols and Punctuation
  [12288, 12351], // Full-width ASCII punctuation variants
  [65280, 65519]]);
}
/**
 * @type {import('reading-time').default}
 */


function readingTime(text, options = {}) {
  let words = 0,
      start = 0,
      end = text.length - 1; // use provided value if available

  const wordsPerMinute = options.wordsPerMinute || 200; // use provided function if available

  const isWordBound = options.wordBound || isAnsiWordBound; // fetch bounds

  while (isWordBound(text[start])) start++;

  while (isWordBound(text[end])) end--; // Add a trailing word bound to make handling edges more convenient


  const normalizedText = `${text}\n`; // calculate the number of words

  for (let i = start; i <= end; i++) {
    // A CJK character is a always word;
    // A non-word bound followed by a word bound / CJK is the end of a word.
    if (isCJK(normalizedText[i]) || !isWordBound(normalizedText[i]) && (isWordBound(normalizedText[i + 1]) || isCJK(normalizedText[i + 1]))) {
      words++;
    } // In case of CJK followed by punctuations, those characters have to be eaten as well


    if (isCJK(normalizedText[i])) {
      while (i <= end && (isPunctuation(normalizedText[i + 1]) || isWordBound(normalizedText[i + 1]))) {
        i++;
      }
    }
  } // reading time stats


  const minutes = words / wordsPerMinute; // Math.round used to resolve floating point funkyness
  //   http://docs.oracle.com/cd/E19957-01/806-3568/ncg_goldberg.html

  const time = Math.round(minutes * 60 * 1000);
  const displayed = Math.ceil(minutes.toFixed(2));
  return {
    text: displayed + " min read",
    minutes: minutes,
    time: time,
    words: words
  };
}
/**
 * Export
 */


exports = readingTime;
export default exports;